$loader-bg: $gray-light;

.loader {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: show;
    z-index: 9999;
}

.loader::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(90deg, $loader-bg 10%, darken($loader-bg, 5%) 90%);
    transition: opacity 0.65s;
    opacity: 0.3;
}

.loader-inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.loader-content {
    left: 50%;
    top: 50%;
    position: absolute;
}
