.custom-toast {
    #toast-container > div {
        margin-top: 20px;
        width: 500px;
        opacity: 1;
        -moz-box-shadow: 0 0 12px #1a1a1a;
        -webkit-box-shadow: 0 0 12px #1a1a1a;
        box-shadow: 0 0 12px #1a1a1a;
    }
    #toast-container > :hover {
        -moz-box-shadow: 0 0 12px #000000;
        -webkit-box-shadow: 0 0 12px #000000;
        box-shadow: 0 0 12px #000000;
    }
}
