table {
    table-layout: fixed;
    width: 100%;

    a {
        &:hover {
            cursor: pointer;
            padding-bottom: 2px;
            border-bottom: 2px solid #3B57A7;
        }

        &.hide-icon {
            opacity: 0;
            cursor: default;
            pointer-events: none;
        }

        &.disabled {
            &.hide-icon {
                opacity: 0;
            }
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    }

    .select {
        text-align: center;
        vertical-align: top;
    }

    thead th,
    tbody td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        em {
            &.fa-sort {
                opacity: 0.6;
                color: rgba(0, 0, 0, 0.225);
            }
        }
    }
}

.table thead th {
    vertical-align: top;
    cursor: pointer;
}

.last-col {
    tr {
        th:last-child {
            width: 15%;
            text-align: center;
        }
        td:last-child {
            text-align: center;
        }
    }
}

td.icon-col {
    text-align: center;
}

th.icon-col {
    width: 15%;
    text-align: center;
}

.card.card-default {
    hr {
        margin: 0;
        border-top: 2px solid #cfdbe2;
    }
}

.pagination {
    justify-content: flex-end;
    padding-right: 0;
}

.page-footer {
    align-items: baseline;
    h5 {
        font-size: 1rem;
    }
}

.in-line-control {
    width: 45px;
    padding: 0;
    height: 100%;
}

.sorted-asc {
    opacity: 1;
    background: -moz-linear-gradient(top, black 50%, rgba(0, 0, 0, 0.225) 50%);
    background: -webkit-linear-gradient(top, black 50%, rgba(0, 0, 0, 0.225) 50%);
    background: linear-gradient(to bottom, black 50%, rgba(0, 0, 0, 0.225) 50%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial; /* reset Font Awesome's display:inline-block */
}

.sorted-desc {
    opacity: 1;
    background: -moz-linear-gradient(bottom, black 50%, rgba(0, 0, 0, 0.225) 50%);
    background: -webkit-linear-gradient(bottom, black 50%, rgba(0, 0, 0, 0.225) 50%);
    background: linear-gradient(to top, black 50%, rgba(0, 0, 0, 0.225) 50%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial; /* reset Font Awesome's display:inline-block */
}
